import * as React from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from "gatsby"
import Layout from "../components/layout/layout";


import './../style/style.scss';

const UnsubscribePage = () => {
    

    return (
        <Layout>
            <>

                <section className="sec-Unsubscribe">

                    <Container>
                        
                        <Row>
                            <Col lg={{ span:8, offset:2 }}>

                                <div className="mb-5 text-center">
                                    <div className="title">Unsubscribe</div>
                                    <h3>Lorem Ipsum is simply dummy text of the printing.</h3>
                                </div>

                                <Form>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="radio"
                                            label="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios1"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="radio"
                                            label="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios2"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="radio"
                                            label="Lorem Ipsum is simply dummy text of the printing and typesetting industry text ever since the 1500s."
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios3"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="radio"
                                            label="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios4"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="radio"
                                            label="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios5"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="radio"
                                            label="Lorem Ipsum is simply dummy text of the printing and typesetting industry text ever since the 1500s."
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios6"
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicEmail">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows={5} type="text" placeholder="Message" />
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s." />
                                    </Form.Group>

                                    <Form.Group className="text-end">
                                        <Button variant="primary" type="button">Unsubscribe</Button>
                                    </Form.Group>
                                </Form>

                            </Col>
                        </Row>
                        
                        
                    </Container>

                </section>

            </>
        </Layout>
    )
}

export default UnsubscribePage
